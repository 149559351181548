<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <validation-observer ref="journalRules">
        <div>
          <b-row>
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Add Journal Entry</th>
                </tr>
              </thead>
            </table>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mt-2">
              <b-form-group>
                <label for="vehicle-type" class="border-bottom">Date* </label>
                <validation-provider #default="{ errors }" name="Vehicle Title Type" rules="required">
                  <flat-pickr v-model="accountData.date" :config="{ dateFormat: 'd-m-Y' }" class="form-control" />

                  <small class="text-danger" v-if="accountData.date == null">Entry Date is required </small>
                  <small class="text-danger" v-else>{{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <label for="accountTitle">Reference</label>
                <b-form-input id="location" v-model="accountData.reference" type="text" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <table class="table border  text-center table-condensed table-sm mt-1">
              <thead>
                <tr>
                  <th style="width:40%" class="text-capitalize">Allocation</th>
                  <th style="width:25%" class="text-capitalize">Price</th>
                  <th style="width:25%" class="text-capitalize">Type</th>
                  <th style="width:10%" class="text-capitalize">#</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td colspan="1">
                    <!-- <b-form-input id="options" placeholder="Additional Sale Options" size="sm" v-model="allocation.allocationAccount" type="text" /> -->
                    <b-form-group label-for="additional-charges">
                      <v-select v-model="allocation.allocationAccount" class="mt-1" :options="dropAccounts" :reduce="(val) => val.value" label="value"> </v-select>
                    </b-form-group>
                  </td>
                  <td>
                    <cleave id="allocationPrice" placeholder="Price" v-model="allocation.allocationPrice" class="form-control" :raw="true" :options="options.number" />
                  </td>

                  <td colspan="1">
                    <b-form-group label-for="additional-charges">
                      <v-select v-model="allocation.allocationType" class="mt-1" :options="['CR', 'DR']"> </v-select>
                    </b-form-group>
                  </td>

                  <td>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :disabled="allocation.allocationAccount == null || allocation.allocationPrice == null" @click="priceCalculation()" class="btn-icon" variant="primary">
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </td>
                </tr>

                <tr style="text-align:center" v-for="(item, index) in accountData.allocationList" :key="index">
                  <td style="text-align:left">{{ item.allocationAccount }}</td>
                  <td>{{ formatPrice(item.allocationPrice) }}</td>
                  <td>{{ item.allocationType }}</td>

                  <td class="text-center">
                    <feather-icon :id="`option-row-${item.id}`" icon="TrashIcon" @click="removeAdditionalItem(index)" size="16" class="cursor-pointer mx-1" />
                  </td>
                </tr>
              </tbody>
            </table>
          </b-row>

          <b-row>
            <b-card-body class="invoice-padding pt-0 mt-1">
              <b-col cols="12">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="saveInvoice" variant="primary" class="mr-1 float-right">
                  Save
                </b-button>
              </b-col>
            </b-card-body>
          </b-row>
        </div>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import flatPickr from 'vue-flatpickr-component';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import accountingStoreModule from '@/views/accounting/accountingStoreModule.js';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import { required, email } from '@validations';
import Ripple from 'vue-ripple-directive';
import { forEach } from 'postcss-rtl/lib/affected-props';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BFormTextarea,
    vSelect,
    //validation
    ValidationProvider,
    ValidationObserver,

    VueNumericInput,
    ToastificationContent,
    Cleave,
    flatPickr,
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      dropCustomers: [],
      dropInventorys: [],
      dropItems: [],
      dropAccounts: [],
      additionalChargeOptions: [],
      additionalSelected: null,

      allocation: {
        allocationAccount: null,
        allocationPrice: null,
        allocationType: null,
      },

      itemFormBlankItem: {
        itemCategory: { id: null, categoryName: null, item: [] },
        type: null,
        cost: null,
      },
      accountData: {
        allocationList: [],
        items: [{}],
        customerId: null,
        id: null,
        date: null,
        reference: null,
        status: null,
      },

      priceCalculationTemp: {},

      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      formShow: false,

      statusType: null,
      status: null,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
      required,
      email,
    };
  },

  directives: {
    Ripple,
  },

  computed: {},

  watch: {},

  methods: {
    priceCalculation() {
      this.accountData.allocationList.push(this.allocation);
      this.allocation = {
        allocationAccount: null,
        allocationPrice: null,
        allocationType: null,
      };
    },

    getCustomerDrops() {
      store
        .dispatch('accounting/getCustomerDropDowns')
        .then((response) => {
          this.dropCustomers = response.data;
        })
        .catch((error) => {});
    },

    getCategoryDrops() {
      store
        .dispatch('accounting/listAccount')
        .then((response) => {
          response.data.forEach((element) => {
            this.dropAccounts.push({ id: element.id, value: element.accountTitle });
          });
          this.getByIdExpense();
        })
        .catch((error) => {});
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    removeAdditionalItem(e) {
      this.$delete(this.accountData.allocationList, e);
    },

    saveInvoice() {
      this.formShow = true;

      let crTotal = 0;
      let drTotal = 0;

      this.accountData.allocationList.forEach((element) => {
        if (element.allocationType == 'CR') {
          crTotal += Number(element.allocationPrice);
        } else {
          drTotal += Number(element.allocationPrice);
        }
      });
      if (crTotal == drTotal) {
        this.$refs.journalRules.validate().then((success) => {
          if (success) {
            if (router.currentRoute.params.id) {
              store
                .dispatch('accounting/updateJournal', this.accountData)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Entry has been saved successfully',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  router.push({ name: 'report-journal' });
                })
                .catch((e) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Something went wrong',
                      text: 'Please try again or report an issue to support',
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  });
                });
            } else {
              store
                .dispatch('accounting/saveJournal', this.accountData)
                .then((response) => {
                  if (response.status == 201) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Successful',
                        text: '✔️ Entry has been saved successfully',
                        icon: 'ThumbsUpIcon',
                        variant: 'success',
                      },
                    });

                    this.formShow = false;

                    router.push({ name: 'report-journal' });
                  }
                })
                .catch((e) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Something went wrong',
                      text: 'Please try again or report an issue to support',
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          } else {
            this.formShow = false;
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Outstanding Allocation Balance',
            text: 'The entries should be allocated equally',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        });
      }

      this.formShow = false;
    },

    getByIdExpense() {
      if (router.currentRoute.params.id) {
        store
          .dispatch('accounting/fetchJournalById', { id: router.currentRoute.params.id })
          .then((response) => {
            this.accountData = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            // router.push({ name: 'quote-list' });
          });
      } else {
        this.formShow = false;
      }
    },
  },
  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },
  created() {
    this.formShow = true;
    this.getCategoryDrops();
    this.getCustomerDrops();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/vue-select.scss';

.form-item-section {
  background-color: #fff;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
